import { useCallback, useState, useEffect } from 'react';
import { PrivyProvider, WalletListEntry } from '@privy-io/react-auth';
import { useAuthState } from '@/context/UserContext';
import TitleGeno from '../../images/pixelton-arena-title.png';
import { MANTLE_MAINNET_CHAIN_ID } from '@/config/constants';

export const privyWalletList: WalletListEntry[] = ['wallet_connect'];

const PrivyWalletProvider = ({ children }: any) => {
  return (
    <PrivyProvider
      appId={import.meta.env.VITE_PRIVY_APP_ID}
      clientId={import.meta.env.VITE_PRIVY_CLIENT_ID}
      config={{
        appearance: {
          walletChainType: 'ethereum-only',
          walletList: privyWalletList,
          loginMessage: `Connect wallet to Pixelton`,
          landingHeader: `Pixelton`,
          logo: TitleGeno,
        },
        supportedChains: [
          // enable when actually needed
          // {
          //   id: ETHEREUM_CHAIN_ID,
          //   name: 'Ethereum',
          //   nativeCurrency: {
          //     name: 'Ether',
          //     symbol: 'ETH',
          //     decimals: 18,
          //   },
          //   rpcUrls: {
          //     default: {
          //       http: ['https://mainnet.infura.io/v3/'],
          //     },
          //   },
          // },

          {
            id: MANTLE_MAINNET_CHAIN_ID,
            name: 'Mantle',
            nativeCurrency: {
              name: 'Mantle Token',
              symbol: 'MNT',
              decimals: 18,
            },
            rpcUrls: {
              default: {
                http: ['https://rpc.mantle.xyz'],
              },
            },
          },
        ],
        defaultChain: {
          id: MANTLE_MAINNET_CHAIN_ID,
          name: 'Mantle',
          nativeCurrency: {
            name: 'Mantle Token',
            symbol: 'MNT',
            decimals: 18,
          },
          rpcUrls: {
            default: {
              http: ['https://rpc.mantle.xyz'],
            },
          },
        },
      }}
    >
      {children}
    </PrivyProvider>
  );
};

export default PrivyWalletProvider;
